<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	import pullLinks from "@/utils/seo"
	// import type from "postcss-pxtorem/lib/type"

	export default {
		data() {
			return {
				currLink: 'https://qifu.hmbinfu.com/#/'
			}
		},
		created() {
			//1、先获取当前页面的URL
			// this.getCurrLink()
			console.log("currLink===>", this.currLink)

			//2、执行提交链接到百度收录的操作
			pullLinks(
				this.currLink
			, {
				site: this.currLink,
				token: "cvTpcJnX30mQyGLm"
			})

		},
		methods: {
			getCurrLink() {
				this.currLink = window.location.href
				// console.log("currLink==>",this.currLink)
			}
		}
	}
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		background: #f5f5f5;
	}

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	/* 设置滚动条的样式 */
	::-webkit-scrollbar {
		width: 0px;
	}

	/* 滚动槽 */
	// ::-webkit-scrollbar-track {
	//   -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
	//   border-radius: 10px;
	// }
	/* 滚动条滑块 */
	// ::-webkit-scrollbar-thumb {
	//   border-radius: 10px;
	//   background: rgba(0, 0, 0, 0.1);
	//   -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
	// }

	// ::-webkit-scrollbar-thumb:window-inactive {
	//   background: rgba(255, 0, 0, 0.4);
	// }
</style>