import axios from "axios"

function pullLinks(data, params) {
	axios.post("http://data.zz.baidu.com/urls", data, {
		params: params,
		headers: {
			'User-Agent': 'curl/7.12.1',
			'Host': 'data.zz.baidu.com',
			'Content-Type': 'text/plain',
			'Content-Length': 83,
		},
	}).then(res => {
		console.log("res:", res)
	}).catch(err => {
		console.log("err:", err)
	})

}

export default pullLinks